import styled from "styled-components";

export const Button = styled.button`
  &,
  &:link,
  &:visited {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-transform: capitalize;
    text-decoration: none;
    padding: 8px 1rem;
    padding-top: ${({ paddingtop }) => paddingtop};
    padding-bottom: ${({ paddingbottom }) => paddingbottom};
    transition: all 200ms;
    border: none;
    border-radius: ${({ bradius }) => (bradius ? bradius : "3px;")};
    width: ${({ wdth }) => wdth};
    height: ${({ hght }) => hght};
    background-color: white;
  }
  &:hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    opacity: 1 !important;
  }
  &:focus {
    outline: none;
  }
`;
