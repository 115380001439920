import React from "react";
import styled from "styled-components";
import { animated } from "react-spring";
import { Image } from "Styles/Images";
import { FullImages } from "Assets/Images/Projects/FullImages";

export const ModalContainer = styled.div`
  background-color: white;
  padding: 5rem;
  border-radius: 4px;
`;

const ModalImage = styled(animated.div)`
  position: fixed;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalBG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
`;
export const ImageContainer = styled(animated.figure)`
  height: 100vh;
  padding: 5rem;
  overflow: hidden;
`;

const Modal = ({ modalIndex = 0, clicked, location }) => {
  return (
    <ModalContainer onClick={() => clicked("close")}>
      <ModalBG />
      <ModalImage>
        <ImageContainer>
          <Image src={FullImages[location][modalIndex]} />
        </ImageContainer>
      </ModalImage>
    </ModalContainer>
  );
};

export default Modal;
