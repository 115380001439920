import React from "react";
import Banner from "Components/Banner/Banner";
import { AutoGridColumn } from "Styles/Grids";
//import { ImageContainer, Image, TileContainer, GreyCube } from "Styles/Images";
//import { LabelContainer, Span } from "Styles/Text";
//import featured from "Assets/Images/Projects/PreviewTiles/bw.jpg";
//import projects from "Assets/Images/Tiles/door.jpg";
import styled from "styled-components";
import { animated } from "react-spring";
import { Icon } from "Styles/Icons";
import { ReactComponent as Logo } from "Assets/Icons/logo.svg";

const CenterIconContainer = styled(animated.div)`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;

const BannerBG = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: var(--color-white-trans);
  z-index: 2;
`;

const Home = () => {
  return (
    <AutoGridColumn numcolumns={1} cspacing={"1fr"} margin={"3px"}>
      <CenterIconContainer>
        <Icon size={"large"} component={<Logo />} />
      </CenterIconContainer>
      <BannerBG />
      <Banner />
      {/* <AutoGridColumn numcolumns={2} cspacing={"1fr"} ggap={"3px"}>
        <TileContainer>
          <ImageContainer hght={"100%"} hover={"hover"}>
            <Image src={featured} alt="" />
          </ImageContainer>
          <GreyCube onClick={() => navigate("/projects/brentwood")} />
          <LabelContainer>
            <Span fontsize={"var(--large-font-size)"} color={"var(--color-white)"}>
              New
            </Span>
          </LabelContainer>
        </TileContainer>
        <TileContainer>
          <ImageContainer hght={"100%"} hover={"hover"}>
            <Image src={projects} alt="" />
          </ImageContainer>
          <GreyCube onClick={() => navigate("/projects")} />
          <LabelContainer>
            <Span fontsize={"var(--large-font-size)"} color={"var(--color-white)"}>
              Projects
            </Span>
          </LabelContainer>
        </TileContainer>
      </AutoGridColumn> */}
    </AutoGridColumn>
  );
};

export default Home;
