import styled from "styled-components";

export const IconLabelGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: ${({ gcgap }) => gcgap};
  align-items: center;
  justify-self: ${({ justifyself }) => justifyself};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingtop }) => paddingtop};
  padding-bottom: ${({ paddingbottom }) => paddingbottom};
  padding-left: ${({ paddingleft }) => paddingleft};
  padding-right: ${({ paddingright }) => paddingright};
  margin-right: ${({ marginright }) => marginright};
  margin-bottom: ${({ marginbottom }) => marginbottom};
  &:hover {
    cursor: ${({ hover }) => (hover ? "pointer" : null)};
  }
  &:hover > svg {
    fill: ${({ hfillcolor }) => hfillcolor};
  }
`;

export const LabelContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: ${({ op }) => (op === "op" ? "1 !important;" : 0)};
  transform: translate(-50%, -50%);
  transition: all 500ms;
`;

export const Span = styled.span`
  display: inline-flex;
  /* text-shadow: 5px 4px 7px #393939; */
  font-size: ${({ fontsize }) => (fontsize ? fontsize : "var(--small-font-size)")};
  font-weight: ${({ fontweight }) => (fontweight ? fontweight : "400")};
  font-style: ${({ fontstyle }) => (fontstyle ? fontstyle : null)};
  text-transform: uppercase;
  /* text-transform: ${({ lowercase }) => (lowercase ? "lowercase" : "capitalize")}; */
  color: ${({ color }) => color};
  align-items: ${({ alignitems }) => alignitems};
  justify-self: ${({ justifyself }) => justifyself};
  align-self: ${({ alignself }) => alignself};
  margin: ${({ margin }) => margin};
  margin-right: ${({ marginright }) => marginright};
`;
