import styled from "styled-components";
import { animated } from "react-spring";

export const PageContainer = styled(animated.main)`
  position: relative;
  /* min-width: 65rem; */
  min-height: 100vh;
`;
export const MainAnimatedContainer = styled(animated.div)`
  position: ${({ pos }) => pos};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingtop }) => paddingtop};
  height: ${({ hght }) => hght};
  width: 100%;
  z-index: ${({ zindex }) => zindex};
`;

export const AnimatedNav = styled(animated.nav)`
  position: ${({ pos }) => pos};
  top: ${({ top }) => top};
  padding: ${({ padding }) => padding};
  height: ${({ hght }) => hght};
  z-index: 100;
`;

// Auto flow option
export const AutoColumns = styled.div`
  display: grid;
  grid-auto-flow: column;
  height: ${({ hght }) => hght};
  width: ${({ wdth }) => wdth};
  grid-row-gap: ${({ grgap }) => grgap};
  grid-column-gap: ${({ gcgap }) => gcgap};
  justify-content: ${({ justifycontent }) => justifycontent};
  align-content: ${({ aligncontent }) => aligncontent};
  align-items: ${({ alignitems }) => alignitems};
  padding: ${({ padding }) => padding};
  margin-bottom: ${({ marginbottom }) => marginbottom};
  background-color: ${({ bgcolor }) => bgcolor};
  transition: height 500ms ease-in-out;
  overflow: hidden;
`;
export const AutoMinRows = styled.div`
  display: grid;
  grid-auto-flow: ${({ numrows }) => `repeat(${numrows}, max-content)`};
  grid-row-gap: ${({ grgap }) => grgap};
  grid-auto-rows: max-content;
  grid-column-gap: ${({ gcgap }) => gcgap};
  justify-content: ${({ justifycontent }) => justifycontent};
  align-items: ${({ alignitems }) => alignitems};
`;
export const AutoGridColumn = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: ${({ numcolumns, cspacing }) => `repeat(${numcolumns}, ${cspacing})`};
  grid-row-gap: ${({ grgap }) => grgap};
  grid-column-gap: ${({ gcgap }) => gcgap};
  grid-gap: ${({ ggap }) => ggap};
  justify-content: ${({ justifycontent }) => justifycontent};
  justify-items: ${({ justifyitems }) => justifyitems};
  align-content: ${({ aligncontent }) => aligncontent};
  align-items: ${({ alignitems }) => alignitems};
  height: ${({ hght }) => hght};
  width: ${({ wdth }) => wdth};
  background-color: ${({ bgcolor }) => bgcolor};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingtop }) => paddingtop};
  padding-bottom: ${({ paddingbottom }) => paddingbottom};
  padding-left: ${({ paddingleft }) => paddingleft};
  padding-right: ${({ paddingright }) => paddingright};
  margin: ${({ margin }) => margin};
  margin-bottom: ${({ marginbottom }) => marginbottom};
  margin-left: ${({ marginleft }) => marginleft};
  opacity: ${({ opacity }) => opacity};
  overflow: hidden;
  &:hover {
    /* transform: ${({ hover }) => (hover ? "scale(1.2);" : null)}; */
    cursor: ${({ hover }) => (hover ? "pointer" : null)};
  }
`;

export const CustomGridColumns = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: ${({ gridcolumns }) => gridcolumns};
  grid-row-gap: ${({ grgap }) => grgap};
  grid-column-gap: ${({ gcgap }) => gcgap};
  grid-gap: ${({ ggap }) => ggap};
  height: ${({ hght }) => hght};
  padding: ${({ padding }) => padding};
  justify-content: ${({ justifycontent }) => justifycontent};
  justify-items: ${({ justifyitems }) => justifyitems};
  align-content: ${({ aligncontent }) => aligncontent};
  align-items: ${({ alignitems }) => alignitems};
  margin-top: ${({ margintop }) => margintop};
  margin-bottom: ${({ marginbottom }) => marginbottom};
`;
// Generic Flex
export const FlexColumnContainer = styled.div`
  position: ${({ pos }) => pos};
  display: flex;
  flex-direction: column;
  height: ${({ hght }) => hght};
  width: ${({ wdth }) => wdth};
  min-width: ${({ minwidth }) => minwidth};
  max-width: ${({ maxwidth }) => maxwidth};
  min-height: ${({ minheight }) => minheight};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingtop }) => paddingtop};
  padding-bottom: ${({ paddingbottom }) => paddingbottom};
  padding-left: ${({ paddingleft }) => paddingleft};
  padding-right: ${({ paddingright }) => paddingright};
  margin-top: ${({ margintop }) => margintop};
  margin-bottom: ${({ marginbottom }) => marginbottom};
  align-items: ${({ alignitems }) => alignitems};
  justify-content: ${({ justifycontent }) => justifycontent};
  background-color: ${({ bgcolor }) => bgcolor};
  overflow: hidden;
  word-wrap: break-word;
  z-index: ${({ zindex }) => zindex};
`;
export const FlexRowContainer = styled.div`
  position: ${({ pos }) => pos};
  display: flex;
  flex-direction: row;
  height: ${({ hght }) => hght};
  width: ${({ wdth }) => wdth};
  padding: ${({ padding }) => padding};
  padding-left: ${({ paddingleft }) => paddingleft};
  padding-right: ${({ paddingright }) => paddingright};
  padding-top: ${({ paddingtop }) => paddingtop};
  padding-bottom: ${({ paddingbottom }) => paddingbottom};
  margin-top: ${({ margintop }) => margintop};
  margin-bottom: ${({ marginbottom }) => marginbottom};
  margin-left: ${({ marginleft }) => marginleft};
  justify-content: ${({ justifycontent }) => justifycontent};
  align-items: ${({ alignitems }) => alignitems};
  background-color: ${({ bgcolor }) => bgcolor};
  border: ${({ brdr }) => brdr};
  overflow: hidden;
`;
export const GalleryFlexRowContainer = styled.div`
  position: ${({ pos }) => pos};
  display: flex;
  flex-direction: row;
  height: ${({ hght }) => hght};
  width: ${({ wdth }) => wdth};
  padding: ${({ padding }) => padding};
  padding-left: ${({ paddingleft }) => paddingleft};
  padding-right: ${({ paddingright }) => paddingright};
  padding-top: ${({ paddingtop }) => paddingtop};
  padding-bottom: ${({ paddingbottom }) => paddingbottom};
  margin-top: ${({ margintop }) => margintop};
  margin-bottom: ${({ marginbottom }) => marginbottom};
  margin-left: ${({ marginleft }) => marginleft};
  justify-content: ${({ justifycontent }) => justifycontent};
  align-items: ${({ alignitems }) => alignitems};
  background-color: ${({ bgcolor }) => bgcolor};
  border: ${({ brdr }) => brdr};
  overflow: hidden;
  &:hover figure {
    transform: scale(1.05);
  }
  &:hover div {
    opacity: 1 !important;
  }
`;
