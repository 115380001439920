import styled from "styled-components";
import { Link } from "react-router-dom";

export const HyperLink = styled(Link)`
  &,
  &:link,
  &:visited {
    text-transform: ${({ lowercase }) => (lowercase ? "lowercase" : "capitalize")};
    text-decoration: none;
    color: inherit;
  }
`;

export const AnchorTag = styled.a`
  &,
  &:link,
  &:visited {
    position: relative;
    width: 100%;
    text-transform: ${({ lowercase }) => (lowercase ? "lowercase" : "uppercase")};
    text-decoration: none;
    color: inherit;
  }
`;
