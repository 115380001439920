import React, { useState, useEffect } from "react";
import { useTrail } from "react-spring";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ImageHoverContainer, ImageContainer, Image } from "Styles/Images";
import { LandscapeImages, PortraitImages } from "Assets/Images/Projects/PreImages";
import { AutoGridColumn, MainAnimatedContainer, GalleryFlexRowContainer, FlexRowContainer } from "Styles/Grids";
import { LabelContainer, Span } from "Styles/Text";
import { Button } from "Styles/Buttons";
import Modal from "Components/Gallery/Modal";

const FlexColumnFigureContainer = styled.div`
  position: relative;
  overflow: hidden;
  word-wrap: break-word;
  &:hover figure {
    transform: scale(1.05);
  }
  &:hover div {
    opacity: 1 !important;
  }
`;

const Gallery = () => {
  const { location } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [modal, setModal] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const onClickModal = (m, index) => {
    setModalIndex(index);
    if (m === "open") {
      setModal(true);
    }
    if (m === "close") {
      setModal(false);
    }
  };

  const trail = useTrail(LandscapeImages[location].length, {
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 0,
  });
  const portraitTrail = useTrail(PortraitImages[location].length, {
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 0,
  });

  const galleryLandscapeImages = trail.map((styles, index) => {
    if (LandscapeImages[location][index]["row"] === "L1") {
      return (
        <>
          <ImageHoverContainer key={index} style={styles} wdth={"100%"} hght={"50rem"} hover={loaded ? "hover" : null}>
            <Image src={LandscapeImages[location][index]["pre"][0]["path"]} />
          </ImageHoverContainer>
          <LabelContainer>
            <Button wdth={"8rem"} margin={"1rem"} onClick={() => onClickModal("open", LandscapeImages[location][index]["pre"][0]["i"])}>
              <Span fontsize={"var(--medium-font-size)"}>View</Span>
            </Button>
          </LabelContainer>
        </>
      );
    }
    if (LandscapeImages[location][index]["row"] === "P1") {
      return (
        <>
          <ImageHoverContainer key={index} style={styles} wdth={"100%"} hght={"80rem"} hover={loaded ? "hover" : null}>
            <Image src={LandscapeImages[location][index]["pre"][0]["path"]} />
          </ImageHoverContainer>
          <LabelContainer>
            <Button wdth={"8rem"} margin={"1rem"} onClick={() => onClickModal("open", LandscapeImages[location][index]["pre"][0]["i"])}>
              <Span fontsize={"var(--medium-font-size)"}>View</Span>
            </Button>
          </LabelContainer>
        </>
      );
    }
    if (LandscapeImages[location][index]["row"] === "third") {
      return (
        <>
          <FlexColumnFigureContainer>
            <ImageHoverContainer key={index} style={styles} hght={"60rem"} hover={"hover"}>
              <Image src={LandscapeImages[location][index]["pre"][0]["path"]} hght={height <= 1000 ? `${height - 120}px` : "95rem"} />
            </ImageHoverContainer>
            <LabelContainer>
              <Button wdth={"8rem"} margin={"1rem"} onClick={() => onClickModal("open", LandscapeImages[location][index]["pre"][0]["i"])}>
                <Span fontsize={"var(--medium-font-size)"}>View</Span>
              </Button>
            </LabelContainer>
          </FlexColumnFigureContainer>
          <FlexColumnFigureContainer>
            <ImageHoverContainer key={index + 1} style={styles} hght={"60rem"} hover={"hover"}>
              <Image src={LandscapeImages[location][index]["pre"][1]["path"]} />
            </ImageHoverContainer>
            <LabelContainer>
              <Button wdth={"8rem"} margin={"1rem"} onClick={() => onClickModal("open", LandscapeImages[location][index]["pre"][1]["i"])}>
                <Span fontsize={"var(--medium-font-size)"}>View</Span>
              </Button>
            </LabelContainer>
          </FlexColumnFigureContainer>
        </>
      );
    }
    if (LandscapeImages[location][index]["row"] === 2) {
      return (
        <>
          <FlexColumnFigureContainer>
            <ImageHoverContainer key={index} style={styles} hght={"60rem"} hover={"hover"}>
              <Image src={LandscapeImages[location][index]["pre"][0]["path"]} hght={height <= 1000 ? `${height - 120}px` : "95rem"} />
            </ImageHoverContainer>
            <LabelContainer>
              <Button wdth={"8rem"} margin={"1rem"} onClick={() => onClickModal("open", LandscapeImages[location][index]["pre"][0]["i"])}>
                <Span fontsize={"var(--medium-font-size)"}>View</Span>
              </Button>
            </LabelContainer>
          </FlexColumnFigureContainer>
          <FlexColumnFigureContainer>
            <ImageHoverContainer key={index + 1} style={styles} hght={"60rem"}>
              <Image src={LandscapeImages[location][index]["pre"][1]["path"]} hght={height <= 1000 ? `${height - 120}px` : "95rem"} />
            </ImageHoverContainer>
            <LabelContainer>
              <Button wdth={"8rem"} margin={"1rem"} onClick={() => onClickModal("open", LandscapeImages[location][index]["pre"][1]["i"])}>
                <Span fontsize={"var(--medium-font-size)"}>View</Span>
              </Button>
            </LabelContainer>
          </FlexColumnFigureContainer>
        </>
      );
    }
    if (LandscapeImages[location][index]["row"] === 3) {
      return (
        <>
          <FlexColumnFigureContainer>
            <ImageHoverContainer key={index} style={styles} hght={"60rem"} hover={"hover"}>
              <Image src={LandscapeImages[location][index]["pre"][0]["path"]} />
            </ImageHoverContainer>
            <LabelContainer>
              <Button wdth={"8rem"} margin={"1rem"} onClick={() => onClickModal("open", LandscapeImages[location][index]["pre"][0]["i"])}>
                <Span fontsize={"var(--medium-font-size)"}>View</Span>
              </Button>
            </LabelContainer>
          </FlexColumnFigureContainer>
          <FlexColumnFigureContainer>
            <ImageHoverContainer key={index + 1} style={styles} hght={"60rem"} hover={"hover"}>
              <Image src={LandscapeImages[location][index]["pre"][1]["path"]} hght={height <= 1000 ? `${height - 120}px` : "95rem"} />
            </ImageHoverContainer>
            <LabelContainer>
              <Button wdth={"8rem"} margin={"1rem"} onClick={() => onClickModal("open", LandscapeImages[location][index]["pre"][1]["i"])}>
                <Span fontsize={"var(--medium-font-size)"}>View</Span>
              </Button>
            </LabelContainer>
          </FlexColumnFigureContainer>
          <FlexColumnFigureContainer>
            <ImageHoverContainer key={index + 2} style={styles} hght={"60rem"} hover={"hover"}>
              <Image src={LandscapeImages[location][index]["pre"][2]["path"]} hght={height <= 1000 ? `${height - 120}px` : "95rem"} />
            </ImageHoverContainer>
            <LabelContainer>
              <Button wdth={"8rem"} margin={"1rem"} onClick={() => onClickModal("open", LandscapeImages[location][index]["pre"][2]["i"])}>
                <Span fontsize={"var(--medium-font-size)"}>View</Span>
              </Button>
            </LabelContainer>
          </FlexColumnFigureContainer>
        </>
      );
    }
    return null;
  });

  const galleryPortraitImages = portraitTrail.map((styles, index) => {
    return (
      <ImageContainer key={index} style={styles}>
        <Image src={PortraitImages[location][index]["path"]} hght={height <= 1000 ? `${height - 120}px` : "70rem"} />
      </ImageContainer>
    );
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });
  });
  useEffect(() => {
    if (!loaded) {
      window.scrollTo(0, 0);
      setLoaded(true);
    }
  }, [loaded, setLoaded]);

  let galleryJsx;
  if (width < 600) {
    galleryJsx = PortraitImages[location].map((_, index) => {
      //console.log(index);
      return (
        <FlexRowContainer key={index} justifycontent={"center"} marginbottom={"5px"}>
          {galleryPortraitImages[index]}
        </FlexRowContainer>
      );
    });
  }
  if (width >= 600) {
    galleryJsx = LandscapeImages[location].map((row, index) => {
      if (row["row"] === "L1") {
        return (
          <GalleryFlexRowContainer key={index + 20} justifycontent={"center"} marginbottom={"5px"} pos={"relative"}>
            {galleryLandscapeImages[index]}
          </GalleryFlexRowContainer>
        );
      }
      if (row["row"] === "P1") {
        return (
          <GalleryFlexRowContainer key={index + 20} justifycontent={"center"} marginbottom={"5px"} pos={"relative"}>
            {galleryLandscapeImages[index]}
          </GalleryFlexRowContainer>
        );
      }
      if (row["row"] === 2) {
        // Two Images in a Row
        return (
          <AutoGridColumn key={index + 100} numcolumns={2} cspacing={"1fr"} gcgap={"5px"} marginbottom={"5px"}>
            {galleryLandscapeImages[index]}
          </AutoGridColumn>
        );
      }
      if (row["row"] === "third") {
        // Two Images in a Row
        return (
          <AutoGridColumn key={index + 100} numcolumns={2} cspacing={"30% 70%"} gcgap={"5px"} marginbottom={"5px"}>
            {galleryLandscapeImages[index]}
          </AutoGridColumn>
        );
      }
      if (row["row"] === 3) {
        // Three Images in a Row
        return (
          <AutoGridColumn key={index + 100} numcolumns={3} cspacing={"1fr"} gcgap={"5px"} marginbottom={"5px"}>
            {galleryLandscapeImages[index]}
          </AutoGridColumn>
        );
      }

      return null;
    });
  }

  return (
    <MainAnimatedContainer>
      {modal ? <Modal modalIndex={modalIndex} clicked={onClickModal} location={location} /> : null}
      {galleryJsx}
    </MainAnimatedContainer>
  );
};

export default Gallery;
