import React from "react";
import styled from "styled-components";
import { FlexColumnContainer, MainAnimatedContainer } from "Styles/Grids";
import { ImageContainer, Image } from "Styles/Images";
import { Span } from "Styles/Text";
import pens from "Assets/Images/pens.jpg";

export const AutoGridColumn = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: ${({ grgap }) => grgap};
  grid-column-gap: ${({ gcgap }) => gcgap};
  grid-gap: ${({ ggap }) => ggap};
  justify-content: ${({ justifycontent }) => justifycontent};
  justify-items: ${({ justifyitems }) => justifyitems};
  align-content: ${({ aligncontent }) => aligncontent};
  align-items: ${({ alignitems }) => alignitems};
  height: ${({ hght }) => hght};
  width: ${({ wdth }) => wdth};
  background-color: ${({ bgcolor }) => bgcolor};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingtop }) => paddingtop};
  padding-bottom: ${({ paddingbottom }) => paddingbottom};
  padding-left: ${({ paddingleft }) => paddingleft};
  padding-right: ${({ paddingright }) => paddingright};
  margin: ${({ margin }) => margin};
  margin-bottom: ${({ marginbottom }) => marginbottom};
  margin-left: ${({ marginleft }) => marginleft};
  opacity: ${({ opacity }) => opacity};
  overflow: hidden;
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const Career = () => {
  const style = {
    color: "red",
    textDecoration: "underline",
  };

  return (
    <MainAnimatedContainer>
      <AutoGridColumn ggap={"2rem"} justifyitems={"center"} paddingtop={"20rem"} padding={"4rem"}>
        <ImageContainer wdth={"40rem"} marginbottom={"4rem"}>
          <Image src={pens} alt="Rambeau" />
        </ImageContainer>

        <FlexColumnContainer>
          <p>
            Rambeau Interior Design is always anxious to meet talented, potential new team members. &nbsp; Applicants should possess a strong understanding of AutoCAD, rendering skills and knowledge of Microsoft Office, Apple Mac and project
            management tools like StudioIT. &nbsp; Previous design firm or showroom experience is a strong plus.
            <br />
            <br />
            Applicants should have a valid CA drivers license for frequent tasks around the Bay Area. &nbsp; Above all, a strong and passionate design sense, a positive attitude and great interpersonal skills are key. &nbsp; Typical duties will
            include all aspects of project management, including assisting principal by resourcing fabrics and finishes, vendor correspondence through quotes and proposals, light deliveries and keeping track of the logistics of the projects assigned
            to the team member. &nbsp; Other non-design related opportunities may also be available, please inquire if interested.
          </p>
          <p>&nbsp;</p>
          <p>Qualified applicants should send their resumes along with salary history and/or requirements to our team</p>
          <p>&nbsp;</p>
          <Span>
            <a style={style} href="mailto:info@rambeaudesign.com">
              info@rambeaudesign.com
            </a>
          </Span>
        </FlexColumnContainer>
      </AutoGridColumn>
    </MainAnimatedContainer>
  );
};

export default Career;
