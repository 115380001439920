import React, { useState, useEffect } from "react";
import { FlexColumnContainer } from "Styles/Grids";
import styled from "styled-components";
import { animated } from "react-spring";
import { Image } from "Styles/Images";

const bi = [
  require("Assets/Images/Banner/banner0.jpg"),
  require("Assets/Images/Banner/banner1.jpg"),
  require("Assets/Images/Banner/banner2.jpg"),
  require("Assets/Images/Banner/banner3.jpg"),
  require("Assets/Images/Banner/banner4.jpg"),
  require("Assets/Images/Banner/banner5.jpg"),
  require("Assets/Images/Banner/banner6.jpg"),
  require("Assets/Images/Banner/banner7.jpg"),
  require("Assets/Images/Banner/banner8.jpg"),
  require("Assets/Images/Banner/banner9.jpg"),
  require("Assets/Images/Banner/banner10.jpg"),
  require("Assets/Images/Banner/banner12.jpg"),
];

const BannerContainer = styled(animated.figure)`
  position: absolute;
  height: 100%;
  width: 100%;
  transition: all 1500ms;
`;

const Banner = () => {
  const [selected, setSelected] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const index = Math.floor(Math.random() * Math.floor(12));
      setSelected(index);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const images = bi.map((image, index) => {
    return (
      <BannerContainer key={index} style={index === selected ? { opacity: 1 } : { opacity: 0 }}>
        <Image src={image} hght={"100%"} alt="" />
      </BannerContainer>
    );
  });
  return (
    <FlexColumnContainer pos={"relative"} hght={"100vh"} marginbottom={"3px"}>
      {images}
    </FlexColumnContainer>
  );
};

export default Banner;
