import React from "react";
import { useSpring } from "react-spring";
import { Routes, Route } from "react-router-dom";
import Home from "Containers/Home/Home";
import Menu from "Components/Menu/Menu";
import Projects from "Containers/Projects/Projects";
//import Gallery from "Components/Gallery/Gallery";
import Gallery2 from "Components/Gallery/Gallery2";
import Contact from "Components/Contact/Contact";
import Careers from "Components/Careers/Careers";
import { PageContainer } from "Styles/Grids";

const Top = () => {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 750 },
  });

  return (
    <PageContainer style={fadeIn}>
      <Menu />
      <Routes>
        <Route path="projects" element={<Projects />} />
        <Route path="projects/:location" element={<Gallery2 />} />
        <Route path="contact" element={<Contact />} />
        <Route path="careers" element={<Careers />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </PageContainer>
  );
};

export default Top;
