// List of Images, titles, etc. Key is the city name that matches the route
// One array of Images per city/project
// The city 'key' needs to match the route path in the TileImages.js file
// Desktop
export const LandscapeImages = {
  brentwood: [
    { row: "L1", pre: [{ path: require("./Brentwood/bw_exterior.jpg"), i: 0 }] },
    {
      row: 2,
      pre: [
        { path: require("./Brentwood/bw_entry_2.jpg"), i: 1 },
        { path: require("./Brentwood/bw_entry_1.jpg"), i: 2 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_powder_room_1.jpg"), i: 3 }] },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_music_room_4.jpg"), i: 4 }] },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_music_room_1.jpg"), i: 5 }] },
    {
      row: 2,
      pre: [
        { path: require("./Brentwood/bw_music_room_3.jpg"), i: 6 },
        { path: require("./Brentwood/bw_details_014.jpg"), i: 7 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_great_room_1.jpg"), i: 8 }] },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_great_room_2.jpg"), i: 9 }] },
    {
      row: 2,
      pre: [
        { path: require("./Brentwood/bw_great_room_3.jpg"), i: 10 },
        { path: require("./Brentwood/bw_bar.jpg"), i: 11 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_dining_room_2.jpg"), i: 12 }] },
    {
      row: 2,
      pre: [
        { path: require("./Brentwood/bw_dining_room_1.jpg"), i: 13 },
        { path: require("./Brentwood/bw_kitchen_4.jpg"), i: 14 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_kitchen_1.jpg"), i: 15 }] },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_kitchen_2.jpg"), i: 16 }] },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_kitchen_3.jpg"), i: 17 }] },
    {
      row: 3,
      pre: [
        { path: require("./Brentwood/bw_family_room.jpg"), i: 18 },
        { path: require("./Brentwood/bw_details_036.jpg"), i: 19 },
        { path: require("./Brentwood/bw_details_042.jpg"), i: 20 },
      ],
    },
    {
      row: 2,
      pre: [
        { path: require("./Brentwood/bw_her_office_2.jpg"), i: 21 },
        { path: require("./Brentwood/bw_her_office_1.jpg"), i: 22 },
      ],
    },
    {
      row: 2,
      pre: [
        { path: require("./Brentwood/bw_his_office.jpg"), i: 23 },
        { path: require("./Brentwood/bw_his_bedroom_2.jpg"), i: 24 },
      ],
    },
    {
      row: "third",
      pre: [
        { path: require("./Brentwood/bw_details_002.jpg"), i: 25 },
        { path: require("./Brentwood/bw_upstairs_landing.jpg"), i: 26 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_wt_bedroom_2.jpg"), i: 27 }] },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_wt_bedroom_1.jpg"), i: 28 }] },
    {
      row: "third",
      pre: [
        { path: require("./Brentwood/bw_details_026.jpg"), i: 29 },
        { path: require("./Brentwood/bw_bunkroom_1.jpg"), i: 30 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_bunkroom_2.jpg"), i: 31 }] },
    {
      row: 3,
      pre: [
        { path: require("./Brentwood/bw_details_035.jpg"), i: 32 },
        { path: require("./Brentwood/bw_his_bedroom_1.jpg"), i: 33 },
        { path: require("./Brentwood/bw_his_bathroom_1.jpg"), i: 34 },
      ],
    },
    {
      row: 2,
      pre: [
        { path: require("./Brentwood/bw_master_bedroom_1.jpg"), i: 35 },
        { path: require("./Brentwood/bw_master_bedroom_2.jpg"), i: 36 },
      ],
    },

    { row: "L1", pre: [{ path: require("./Brentwood/bw_master_her_bathroom_1.jpg"), i: 37 }] },
    {
      row: 2,
      pre: [
        { path: require("./Brentwood/bw_master_her_bathroom_2.jpg"), i: 38 },
        { path: require("./Brentwood/bw_master_his_bathroom_1.jpg"), i: 39 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_primary_patio.jpg"), i: 40 }] },

    {
      row: 3,
      pre: [
        { path: require("./Brentwood/bw_laundry.jpg"), i: 41 },
        { path: require("./Brentwood/bw_laundry_2.jpg"), i: 42 },
        { path: require("./Brentwood/bw_elevator.jpg"), i: 43 },
      ],
    },
    {
      row: 3,
      pre: [
        { path: require("./Brentwood/bw_theater_1.jpg"), i: 44 },
        { path: require("./Brentwood/bw_theater_2.jpg"), i: 45 },
        { path: require("./Brentwood/bw_theater_3.jpg"), i: 46 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_game_room_4.jpg"), i: 47 }] },
    {
      row: 2,
      pre: [
        { path: require("./Brentwood/bw_game_room_3.jpg"), i: 48 },
        { path: require("./Brentwood/bw_game_room_1.jpg"), i: 49 },
      ],
    },

    { row: "L1", pre: [{ path: require("./Brentwood/bw_game_room_2.jpg"), i: 50 }] },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_wine_3.jpg"), i: 51 }] },
    {
      row: 3,
      pre: [
        { path: require("./Brentwood/bw_wine_2.jpg"), i: 52 },
        { path: require("./Brentwood/bw_wine_1.jpg"), i: 53 },
        { path: require("./Brentwood/bw_t_bath_1.jpg"), i: 54 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Brentwood/bw_game_patio.jpg"), i: 55 }] },
  ],
  laurelhurst: [
    {
      row: 2,
      pre: [
        { path: require("./Laurelhurst/1-l-lake.jpg"), i: 0 },
        { path: require("./Laurelhurst/2-l-deck.jpg"), i: 1 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Laurelhurst/3-l-dining-1.jpg"), i: 2 }] },
    { row: "L1", pre: [{ path: require("./Laurelhurst/6-l-living-3.jpg"), i: 3 }] },
    {
      row: 2,
      pre: [
        { path: require("./Laurelhurst/5-l-living-1.jpg"), i: 4 },
        { path: require("./Laurelhurst/4-l-dining-2.jpg"), i: 5 },
      ],
    },
    {
      row: 2,
      pre: [
        { path: require("./Laurelhurst/7-l-living-6.jpg"), i: 6 },
        { path: require("./Laurelhurst/8-l-living-7.jpg"), i: 7 },
      ],
    },
    {
      row: "third",
      pre: [
        { path: require("./Laurelhurst/9-l-library-1.jpg"), i: 8 },
        { path: require("./Laurelhurst/10-l-kitchen-1.jpg"), i: 9 },
      ],
    },
    {
      row: 2,
      pre: [
        { path: require("./Laurelhurst/11-l-kitchen-3.jpg"), i: 10 },
        { path: require("./Laurelhurst/12-l-kitchen-2.jpg"), i: 11 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Laurelhurst/13-l-family-1.jpg"), i: 12 }] },
    {
      row: "third",
      pre: [
        { path: require("./Laurelhurst/14-l-master-1.jpg"), i: 13 },
        { path: require("./Laurelhurst/15-l-master-2.jpg"), i: 14 },
      ],
    },
    {
      row: 2,
      pre: [
        { path: require("./Laurelhurst/16-l-kids-1.jpg"), i: 15 },
        { path: require("./Laurelhurst/17-l-kids-2.jpg"), i: 16 },
      ],
    },

    {
      row: 2,
      pre: [
        { path: require("./Laurelhurst/18-l-bath.jpg"), i: 17 },
        { path: require("./Laurelhurst/19-l-guest-2.jpg"), i: 18 },
      ],
    },

    { row: "L1", pre: [{ path: require("./Laurelhurst/20-l-guest-3.jpg"), i: 19 }] },
    { row: "P1", pre: [{ path: require("./Laurelhurst/21-l-laundry.jpg"), i: 20 }] },
    { row: "L1", pre: [{ path: require("./Laurelhurst/22-l-play-1.jpg"), i: 21 }] },
    { row: "L1", pre: [{ path: require("./Laurelhurst/23-l-play-2.jpg"), i: 22 }] },
    { row: "L1", pre: [{ path: require("./Laurelhurst/24-l-play-3.jpg"), i: 23 }] },
    { row: "P1", pre: [{ path: require("./Laurelhurst/25-l-art-1.jpg"), i: 24 }] },
  ],
  santamonica: [
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_Entry.jpg"), i: 0 }] },
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_Great_Room.jpg"), i: 1 }] },
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_Bar.jpg"), i: 2 }] },
    {
      row: "third",
      pre: [
        { path: require("./SantaMonica/21st_Kitchen_Nook.jpg"), i: 3 },
        { path: require("./SantaMonica/21st_Kitchen.jpg"), i: 4 },
      ],
    },
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_Dining_Room.jpg"), i: 5 }] },
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_Patio_2.jpg"), i: 6 }] },
    {
      row: "third",
      pre: [
        { path: require("./SantaMonica/21st_TV_Room.jpg"), i: 7 },
        { path: require("./SantaMonica/21st_TV_Room_2.jpg"), i: 8 },
      ],
    },
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_Guest_Bedroom.jpg"), i: 9 }] },
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_Stairs.jpg"), i: 10 }] },
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_Master_Bedroom.jpg"), i: 11 }] },
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_Kids_Room.jpg"), i: 12 }] },
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_Kids_Room_2.jpg"), i: 13 }] },
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_J&J_Bathroom.jpg"), i: 14 }] },
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_Office.jpg"), i: 15 }] },
    { row: "L1", pre: [{ path: require("./SantaMonica/21st_Nanny_Room.jpg"), i: 16 }] },
  ],
  marvista: [
    { row: "L1", pre: [{ path: require("./MarVista/mv_living_2.jpg"), i: 0 }] },
    { row: "L1", pre: [{ path: require("./MarVista/mv_living_3.jpg"), i: 1 }] },
    { row: "L1", pre: [{ path: require("./MarVista/mv_living_4.jpg"), i: 2 }] },
    { row: "L1", pre: [{ path: require("./MarVista/mv_living_1.jpg"), i: 3 }] },
    { row: "L1", pre: [{ path: require("./MarVista/mv_detail_008.jpg"), i: 4 }] },
    { row: "L1", pre: [{ path: require("./MarVista/mv_detail_042.jpg"), i: 5 }] },
    { row: "L1", pre: [{ path: require("./MarVista/mv_light_detail_1.jpg"), i: 6 }] },
    { row: "L1", pre: [{ path: require("./MarVista/mv_dining.jpg"), i: 7 }] },
    { row: "L1", pre: [{ path: require("./MarVista/mv_light_detail_2.jpg"), i: 8 }] },
    { row: "L1", pre: [{ path: require("./MarVista/mv_family.jpg"), i: 9 }] },
    { row: "L1", pre: [{ path: require("./MarVista/mv_master.jpg"), i: 10 }] },
    { row: "L1", pre: [{ path: require("./MarVista/mv_office_1.jpg"), i: 11 }] },
    { row: "L1", pre: [{ path: require("./MarVista/mv_office_2.jpg"), i: 12 }] },
  ],
  woodsideone: [
    {
      row: 2,
      pre: [
        { path: require("./WoodsideOne/w1_entry.jpg"), i: 0 },
        { path: require("./WoodsideOne/w1_living.jpg"), i: 1 },
      ],
    },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_hall.jpg"), i: 2 }] },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_dining.jpg"), i: 3 }] },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_kitchen.jpg"), i: 4 }] },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_nook.jpg"), i: 5 }] },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_mud1.jpg"), i: 6 }] },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_mud2.jpg"), i: 7 }] },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_guestbath.jpg"), i: 8 }] },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_master.jpg"), i: 9 }] },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_masterbath1.jpg"), i: 10 }] },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_masterbath2.jpg"), i: 11 }] },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_masterbath3.jpg"), i: 12 }] },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_girls.jpg"), i: 13 }] },
    { row: "L1", pre: [{ path: require("./WoodsideOne/w1_girlsbath.jpg"), i: 13 }] },
  ],
  woodsidetwo: [
    {
      row: 2,
      pre: [
        { path: require("./WoodsideTwo/w2_entrance.jpg"), i: 0 },
        { path: require("./WoodsideTwo/w2_hall.jpg"), i: 1 },
      ],
    },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_fountain.jpg"), i: 2 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_dining.jpg"), i: 3 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_powder.jpg"), i: 4 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_kitchen2.jpg"), i: 5 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_kitchen3.jpg"), i: 6 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_kitchen1.jpg"), i: 7 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_living_1.jpg"), i: 8 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_living_2.jpg"), i: 9 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_guest.jpg"), i: 10 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_guestbath1.jpg"), i: 11 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_guestbath2.jpg"), i: 12 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_pictures.jpg"), i: 13 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_masterbath2.jpg"), i: 14 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_masterbath1.jpg"), i: 15 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_playroom1.jpg"), i: 16 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_playroom2.jpg"), i: 17 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_bedroom1.jpg"), i: 18 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_bedroom2.jpg"), i: 19 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_outside_1.jpg"), i: 20 }] },
    { row: "L1", pre: [{ path: require("./WoodsideTwo/w2_outside_2.jpg"), i: 21 }] },
  ],
  woodsidethree: [
    { row: "L1", pre: [{ path: require("./WoodsideThree/w3_guest2.jpg"), i: 0 }] },
    { row: "L1", pre: [{ path: require("./WoodsideThree/w3_guest3.jpg"), i: 1 }] },
    { row: "L1", pre: [{ path: require("./WoodsideThree/w3_guest1.jpg"), i: 2 }] },
    { row: "L1", pre: [{ path: require("./WoodsideThree/w3_guest4.jpg"), i: 3 }] },
    { row: "L1", pre: [{ path: require("./WoodsideThree/w3_boys1.jpg"), i: 4 }] },
  ],
  menloparkone: [
    { row: "L1", pre: [{ path: require("./MenloParkOne/mp_den1.jpg"), i: 0 }] },
    { row: "L1", pre: [{ path: require("./MenloParkOne/mp_den2.jpg"), i: 1 }] },
    { row: "L1", pre: [{ path: require("./MenloParkOne/mp_piano.jpg"), i: 2 }] },
    { row: "L1", pre: [{ path: require("./MenloParkOne/mp_kitchen.jpg"), i: 3 }] },
    { row: "L1", pre: [{ path: require("./MenloParkOne/mp_living.jpg"), i: 4 }] },
    { row: "L1", pre: [{ path: require("./MenloParkOne/mp_art.jpg"), i: 5 }] },
    { row: "L1", pre: [{ path: require("./MenloParkOne/mp_playroom.jpg"), i: 6 }] },
  ],
  menloparktwo: [
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_front.jpg"), i: 0 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_den.jpg"), i: 2 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_living1.jpg"), i: 3 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_dining2.jpg"), i: 4 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_dining1.jpg"), i: 5 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_kitchen1.jpg"), i: 6 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_kitchennook1.jpg"), i: 7 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_office1.jpg"), i: 8 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_office2.jpg"), i: 9 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_kitchen2.jpg"), i: 10 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_door.jpg"), i: 11 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_stairs.jpg"), i: 12 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_girlsroom.jpg"), i: 13 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_boysroom.jpg"), i: 14 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_kidsbath.jpg"), i: 15 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_master.jpg"), i: 16 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_guesthouse.jpg"), i: 17 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_guestbath.jpg"), i: 18 }] },
    { row: "L1", pre: [{ path: require("./MenloParkTwo/mp_guestbath2.jpg"), i: 19 }] },
  ],
  menloparkthree: [
    {
      row: "third",
      pre: [
        { path: require("./MenloParkThree/mp_front.jpg"), i: 0 },
        { path: require("./MenloParkThree/mp_entry.jpg"), i: 1 },
      ],
    },
    {
      row: "third",
      pre: [
        { path: require("./MenloParkThree/mp_nook.jpg"), i: 2 },
        { path: require("./MenloParkThree/mp_living.jpg"), i: 3 },
      ],
    },
    { row: "L1", pre: [{ path: require("./MenloParkThree/mp_dining.jpg"), i: 4 }] },
    { row: "L1", pre: [{ path: require("./MenloParkThree/mp_guestbath.jpg"), i: 5 }] },
    {
      row: 2,
      pre: [
        { path: require("./MenloParkThree/mp_masterbath2.jpg"), i: 6 },
        { path: require("./MenloParkThree/mp_masterbath1.jpg"), i: 7 },
      ],
    },
    { row: "L1", pre: [{ path: require("./MenloParkThree/mp_kidsbed.jpg"), i: 8 }] },
    {
      row: 2,
      pre: [
        { path: require("./MenloParkThree/mp_kidsbath_1.jpg"), i: 9 },
        { path: require("./MenloParkThree/mp_kidsbath_2.jpg"), i: 10 },
      ],
    },
    { row: "L1", pre: [{ path: require("./MenloParkThree/mp_office.jpg"), i: 11 }] },
  ],
  paloalto: [
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_living_room.jpg"), i: 0 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_living_nook.jpg"), i: 1 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_dining.jpg"), i: 2 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_kitchen_nook.jpg"), i: 3 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_kitchen.jpg"), i: 4 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_powderbath.jpg"), i: 5 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_den.jpg"), i: 6 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_family.jpg"), i: 7 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_hall.jpg"), i: 8 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_master.jpg"), i: 9 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_guest.jpg"), i: 10 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_kidsbath1.jpg"), i: 11 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_boys.jpg"), i: 12 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_kidsbath2.jpg"), i: 13 }] },
    { row: "L1", pre: [{ path: require("./PaloAlto/pa_toys.jpg"), i: 14 }] },
  ],
  sanfrancisco: [
    { row: "L1", pre: [{ path: require("./SanFrancisco/sf_living.jpg"), i: 0 }] },
    { row: "L1", pre: [{ path: require("./SanFrancisco/sf_kitchen_1.jpg"), i: 1 }] },
    { row: "L1", pre: [{ path: require("./SanFrancisco/sf_kitchen_2.jpg"), i: 2 }] },
    { row: "L1", pre: [{ path: require("./SanFrancisco/sf_masterview.jpg"), i: 3 }] },
    { row: "L1", pre: [{ path: require("./SanFrancisco/sf_master.jpg"), i: 4 }] },
    { row: "L1", pre: [{ path: require("./SanFrancisco/sf_masterbath_3.jpg"), i: 5 }] },
    { row: "L1", pre: [{ path: require("./SanFrancisco/sf_masterbath_2.jpg"), i: 6 }] },
    { row: "L1", pre: [{ path: require("./SanFrancisco/sf_masterbath_1.jpg"), i: 7 }] },
    { row: "L1", pre: [{ path: require("./SanFrancisco/sf_master_makeup.jpg"), i: 8 }] },
    { row: "L1", pre: [{ path: require("./SanFrancisco/sf_boys_1.jpg"), i: 9 }] },
    { row: "L1", pre: [{ path: require("./SanFrancisco/sf_boysbath.jpg"), i: 10 }] },
    { row: "L1", pre: [{ path: require("./SanFrancisco/sf_boys_2.jpg"), i: 11 }] },
  ],
  napa: [
    { row: "L1", pre: [{ path: require("./Napa/napa_entry.jpg"), i: 0 }] },
    { row: "L1", pre: [{ path: require("./Napa/napa_great_room_1.jpg"), i: 1 }] },
    {
      row: 3,
      pre: [
        { path: require("./Napa/napa_great_room_2.jpg"), i: 2 },
        { path: require("./Napa/napa_reading_nook.jpg"), i: 3 },
        { path: require("./Napa/napa_kitchen.jpg"), i: 4 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Napa/napa_pool_1.jpg"), i: 5 }] },
    { row: "L1", pre: [{ path: require("./Napa/napa_pool_2.jpg"), i: 6 }] },
    {
      row: 2,
      pre: [
        { path: require("./Napa/napa_downstairs_guest_1.jpg"), i: 7 },
        { path: require("./Napa/napa_powderbath_2.jpg"), i: 8 },
      ],
    },
    {
      row: "third",
      pre: [
        { path: require("./Napa/napa_guest_bath_1.jpg"), i: 9 },
        { path: require("./Napa/napa_powderbath_1.jpg"), i: 10 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Napa/napa_downstairs_guest_2.jpg"), i: 11 }] },
    { row: "L1", pre: [{ path: require("./Napa/napa_bunkroom.jpg"), i: 12 }] },
    {
      row: 2,
      pre: [
        { path: require("./Napa/napa_bb_detail_007.jpg"), i: 13 },
        { path: require("./Napa/napa_bb_detail_012.jpg"), i: 14 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Napa/napa_master.jpg"), i: 15 }] },
    {
      row: "third",
      pre: [
        { path: require("./Napa/napa_b_detail_1.jpg"), i: 16 },
        { path: require("./Napa/napa_master.jpg"), i: 17 },
      ],
    },
    {
      row: 3,
      pre: [
        { path: require("./Napa/napa_master_bath.jpg"), i: 18 },
        { path: require("./Napa/napa_mb_detail_005.jpg"), i: 19 },
        { path: require("./Napa/napa_mb_detail_007.jpg"), i: 20 },
      ],
    },
    { row: "L1", pre: [{ path: require("./Napa/napa_gameroom_1.jpg"), i: 21 }] },
    {
      row: 2,
      pre: [
        { path: require("./Napa/napa_gr_detail_1.jpg"), i: 22 },
        { path: require("./Napa/napa_gr_detail_2.jpg"), i: 23 },
      ],
    },
  ],
};

// Mobile
export const PortraitImages = {
  brentwood: [
    { path: require("./Brentwood/bw_exterior.jpg") },
    { path: require("./Brentwood/bw_entry_2.jpg") },
    { path: require("./Brentwood/bw_entry_1.jpg") },
    { path: require("./Brentwood/bw_powder_room_1.jpg") },
    { path: require("./Brentwood/bw_music_room_4.jpg") },
    { path: require("./Brentwood/bw_music_room_1.jpg") },
    { path: require("./Brentwood/bw_music_room_3.jpg") },
    { path: require("./Brentwood/bw_details_014.jpg") },
    { path: require("./Brentwood/bw_great_room_1.jpg") },
    { path: require("./Brentwood/bw_great_room_2.jpg") },
    { path: require("./Brentwood/bw_great_room_3.jpg") },
    { path: require("./Brentwood/bw_bar.jpg") },
    { path: require("./Brentwood/bw_dining_room_2.jpg") },
    { path: require("./Brentwood/bw_dining_room_1.jpg") },
    { path: require("./Brentwood/bw_kitchen_4.jpg") },
    { path: require("./Brentwood/bw_kitchen_1.jpg") },
    { path: require("./Brentwood/bw_kitchen_2.jpg") },
    { path: require("./Brentwood/bw_kitchen_3.jpg") },
    { path: require("./Brentwood/bw_family_room.jpg") },
    { path: require("./Brentwood/bw_details_036.jpg") },
    { path: require("./Brentwood/bw_details_042.jpg") },
    { path: require("./Brentwood/bw_her_office_2.jpg") },
    { path: require("./Brentwood/bw_her_office_1.jpg") },
    { path: require("./Brentwood/bw_his_office.jpg") },
    { path: require("./Brentwood/bw_his_bedroom_2.jpg") },
    { path: require("./Brentwood/bw_details_002.jpg") },
    { path: require("./Brentwood/bw_upstairs_landing.jpg") },
    { path: require("./Brentwood/bw_wt_bedroom_2.jpg") },
    { path: require("./Brentwood/bw_wt_bedroom_1.jpg") },
    { path: require("./Brentwood/bw_details_026.jpg") },
    { path: require("./Brentwood/bw_bunkroom_1.jpg") },
    { path: require("./Brentwood/bw_bunkroom_2.jpg") },
    { path: require("./Brentwood/bw_details_035.jpg") },
    { path: require("./Brentwood/bw_his_bedroom_1.jpg") },
    { path: require("./Brentwood/bw_his_bathroom_1.jpg") },
    { path: require("./Brentwood/bw_master_bedroom_1.jpg") },
    { path: require("./Brentwood/bw_master_bedroom_2.jpg") },
    { path: require("./Brentwood/bw_master_her_bathroom_1.jpg") },
    { path: require("./Brentwood/bw_master_her_bathroom_2.jpg") },
    { path: require("./Brentwood/bw_master_his_bathroom_1.jpg") },
    { path: require("./Brentwood/bw_primary_patio.jpg") },
    { path: require("./Brentwood/bw_laundry.jpg") },
    { path: require("./Brentwood/bw_laundry_2.jpg") },
    { path: require("./Brentwood/bw_elevator.jpg") },
    { path: require("./Brentwood/bw_theater_1.jpg") },
    { path: require("./Brentwood/bw_theater_2.jpg") },
    { path: require("./Brentwood/bw_theater_3.jpg") },
    { path: require("./Brentwood/bw_game_room_4.jpg") },
    { path: require("./Brentwood/bw_game_room_3.jpg") },
    { path: require("./Brentwood/bw_game_room_1.jpg") },
    { path: require("./Brentwood/bw_game_room_2.jpg") },
    { path: require("./Brentwood/bw_wine_3.jpg") },
    { path: require("./Brentwood/bw_wine_2.jpg") },
    { path: require("./Brentwood/bw_wine_1.jpg") },
    { path: require("./Brentwood/bw_t_bath_1.jpg") },
    { path: require("./Brentwood/bw_game_patio.jpg") },
  ],
  laurelhurst: [
    { path: require("./Laurelhurst/1-l-lake.jpg") },
    { path: require("./Laurelhurst/2-l-deck.jpg") },
    { path: require("./Laurelhurst/3-l-dining-1.jpg") },
    { path: require("./Laurelhurst/4-l-dining-2.jpg") },
    { path: require("./Laurelhurst/5-l-living-1.jpg") },
    { path: require("./Laurelhurst/6-l-living-3.jpg") },
    { path: require("./Laurelhurst/7-l-living-6.jpg") },
    { path: require("./Laurelhurst/8-l-living-7.jpg") },
    { path: require("./Laurelhurst/9-l-library-1.jpg") },
    { path: require("./Laurelhurst/10-l-kitchen-1.jpg") },
    { path: require("./Laurelhurst/11-l-kitchen-3.jpg") },
    { path: require("./Laurelhurst/12-l-kitchen-2.jpg") },
    { path: require("./Laurelhurst/13-l-family-1.jpg") },
    { path: require("./Laurelhurst/14-l-master-1.jpg") },
    { path: require("./Laurelhurst/15-l-master-2.jpg") },
    { path: require("./Laurelhurst/16-l-kids-1.jpg") },
    { path: require("./Laurelhurst/17-l-kids-2.jpg") },
    { path: require("./Laurelhurst/18-l-bath.jpg") },
    { path: require("./Laurelhurst/19-l-guest-2.jpg") },
    { path: require("./Laurelhurst/20-l-guest-3.jpg") },
    { path: require("./Laurelhurst/21-l-laundry.jpg") },
    { path: require("./Laurelhurst/22-l-play-1.jpg") },
    { path: require("./Laurelhurst/23-l-play-2.jpg") },
    { path: require("./Laurelhurst/24-l-play-3.jpg") },
    { path: require("./Laurelhurst/25-l-art-1.jpg") },
  ],
  santamonica: [
    { path: require("./SantaMonica/21st_Entry.jpg") },
    { path: require("./SantaMonica/21st_Great_Room.jpg") },
    { path: require("./SantaMonica/21st_Bar.jpg") },
    { path: require("./SantaMonica/21st_Kitchen_Nook.jpg") },
    { path: require("./SantaMonica/21st_Kitchen.jpg") },
    { path: require("./SantaMonica/21st_Dining_Room.jpg") },
    { path: require("./SantaMonica/21st_Patio_2.jpg") },
    { path: require("./SantaMonica/21st_Guest_Bedroom.jpg") },
    { path: require("./SantaMonica/21st_Stairs.jpg") },
    { path: require("./SantaMonica/21st_Master_Bedroom.jpg") },
    { path: require("./SantaMonica/21st_Kids_Room.jpg") },
    { path: require("./SantaMonica/21st_Kids_Room_2.jpg") },
    { path: require("./SantaMonica/21st_J&J_Bathroom.jpg") },
    { path: require("./SantaMonica/21st_Office.jpg") },
    { path: require("./SantaMonica/21st_Nanny_Room.jpg") },
  ],
  marvista: [
    { path: require("./MarVista/mv_living_2.jpg") },
    { path: require("./MarVista/mv_living_3.jpg") },
    { path: require("./MarVista/mv_living_4.jpg") },
    { path: require("./MarVista/mv_living_1.jpg") },
    { path: require("./MarVista/mv_detail_008.jpg") },
    { path: require("./MarVista/mv_detail_042.jpg") },
    { path: require("./MarVista/mv_light_detail_1.jpg") },
    { path: require("./MarVista/mv_dining.jpg") },
    { path: require("./MarVista/mv_light_detail_2.jpg") },
    { path: require("./MarVista/mv_family.jpg") },
    { path: require("./MarVista/mv_master.jpg") },
    { path: require("./MarVista/mv_master.jpg") },
    { path: require("./MarVista/mv_office_1.jpg") },
    { path: require("./MarVista/mv_office_2.jpg") },
  ],
  woodsideone: [
    { path: require("./WoodsideOne/w1_entry.jpg") },
    { path: require("./WoodsideOne/w1_living.jpg") },
    { path: require("./WoodsideOne/w1_hall.jpg") },
    { path: require("./WoodsideOne/w1_dining.jpg") },
    { path: require("./WoodsideOne/w1_kitchen.jpg") },
    { path: require("./WoodsideOne/w1_nook.jpg") },
    { path: require("./WoodsideOne/w1_mud1.jpg") },
    { path: require("./WoodsideOne/w1_mud2.jpg") },
    { path: require("./WoodsideOne/w1_guestbath.jpg") },
    { path: require("./WoodsideOne/w1_master.jpg") },
    { path: require("./WoodsideOne/w1_masterbath1.jpg") },
    { path: require("./WoodsideOne/w1_masterbath2.jpg") },
    { path: require("./WoodsideOne/w1_masterbath3.jpg") },
    { path: require("./WoodsideOne/w1_girls.jpg") },
    { path: require("./WoodsideOne/w1_girlsbath.jpg") },
  ],
  woodsidetwo: [
    { path: require("./WoodsideTwo/w2_entrance.jpg") },
    { path: require("./WoodsideTwo/w2_hall.jpg") },
    { path: require("./WoodsideTwo/w2_fountain.jpg") },
    { path: require("./WoodsideTwo/w2_dining.jpg") },
    { path: require("./WoodsideTwo/w2_powder.jpg") },
    { path: require("./WoodsideTwo/w2_kitchen2.jpg") },
    { path: require("./WoodsideTwo/w2_kitchen3.jpg") },
    { path: require("./WoodsideTwo/w2_kitchen1.jpg") },
    { path: require("./WoodsideTwo/w2_living_1.jpg") },
    { path: require("./WoodsideTwo/w2_living_2.jpg") },
    { path: require("./WoodsideTwo/w2_guest.jpg") },
    { path: require("./WoodsideTwo/w2_guestbath1.jpg") },
    { path: require("./WoodsideTwo/w2_guestbath2.jpg") },
    { path: require("./WoodsideTwo/w2_pictures.jpg") },
    { path: require("./WoodsideTwo/w2_masterbath2.jpg") },
    { path: require("./WoodsideTwo/w2_masterbath1.jpg") },
    { path: require("./WoodsideTwo/w2_playroom1.jpg") },
    { path: require("./WoodsideTwo/w2_playroom2.jpg") },
    { path: require("./WoodsideTwo/w2_bedroom1.jpg") },
    { path: require("./WoodsideTwo/w2_bedroom2.jpg") },
    { path: require("./WoodsideTwo/w2_outside_1.jpg") },
    { path: require("./WoodsideTwo/w2_outside_2.jpg") },
  ],
  woodsidethree: [
    { path: require("./WoodsideThree/w3_guest2.jpg") },
    { path: require("./WoodsideThree/w3_guest3.jpg") },
    { path: require("./WoodsideThree/w3_guest1.jpg") },
    { path: require("./WoodsideThree/w3_guest4.jpg") },
    { path: require("./WoodsideThree/w3_boys1.jpg") },
  ],
  menloparkone: [
    { path: require("./MenloParkOne/mp_den1.jpg") },
    { path: require("./MenloParkOne/mp_den2.jpg") },
    { path: require("./MenloParkOne/mp_piano.jpg") },
    { path: require("./MenloParkOne/mp_kitchen.jpg") },
    { path: require("./MenloParkOne/mp_living.jpg") },
    { path: require("./MenloParkOne/mp_art.jpg") },
    { path: require("./MenloParkOne/mp_playroom.jpg") },
  ],
  menloparktwo: [
    { path: require("./MenloParkTwo/mp_front.jpg") },
    { path: require("./MenloParkTwo/mp_den.jpg") },
    { path: require("./MenloParkTwo/mp_living1.jpg") },
    { path: require("./MenloParkTwo/mp_dining2.jpg") },
    { path: require("./MenloParkTwo/mp_dining1.jpg") },
    { path: require("./MenloParkTwo/mp_kitchen1.jpg") },
    { path: require("./MenloParkTwo/mp_kitchennook1.jpg") },
    { path: require("./MenloParkTwo/mp_office1.jpg") },
    { path: require("./MenloParkTwo/mp_office2.jpg") },
    { path: require("./MenloParkTwo/mp_kitchen2.jpg") },
    { path: require("./MenloParkTwo/mp_door.jpg") },
    { path: require("./MenloParkTwo/mp_stairs.jpg") },
    { path: require("./MenloParkTwo/mp_girlsroom.jpg") },
    { path: require("./MenloParkTwo/mp_boysroom.jpg") },
    { path: require("./MenloParkTwo/mp_kidsbath.jpg") },
    { path: require("./MenloParkTwo/mp_master.jpg") },
    { path: require("./MenloParkTwo/mp_guesthouse.jpg") },
    { path: require("./MenloParkTwo/mp_guestbath.jpg") },
    { path: require("./MenloParkTwo/mp_guestbath2.jpg") },
  ],
  menloparkthree: [
    { path: require("./MenloParkThree/mp_front.jpg") },
    { path: require("./MenloParkThree/mp_entry.jpg") },
    { path: require("./MenloParkThree/mp_nook.jpg") },
    { path: require("./MenloParkThree/mp_living.jpg") },
    { path: require("./MenloParkThree/mp_dining.jpg") },
    { path: require("./MenloParkThree/mp_guestbath.jpg") },
    { path: require("./MenloParkThree/mp_masterbath2.jpg") },
    { path: require("./MenloParkThree/mp_masterbath1.jpg") },
    { path: require("./MenloParkThree/mp_kidsbed.jpg") },
    { path: require("./MenloParkThree/mp_kidsbath_1.jpg") },
    { path: require("./MenloParkThree/mp_kidsbath_2.jpg") },
    { path: require("./MenloParkThree/mp_office.jpg") },
  ],
  paloalto: [
    { path: require("./PaloAlto/pa_living_room.jpg") },
    { path: require("./PaloAlto/pa_living_nook.jpg") },
    { path: require("./PaloAlto/pa_dining.jpg") },
    { path: require("./PaloAlto/pa_kitchen_nook.jpg") },
    { path: require("./PaloAlto/pa_kitchen.jpg") },
    { path: require("./PaloAlto/pa_powderbath.jpg") },
    { path: require("./PaloAlto/pa_den.jpg") },
    { path: require("./PaloAlto/pa_family.jpg") },
    { path: require("./PaloAlto/pa_hall.jpg") },
    { path: require("./PaloAlto/pa_master.jpg") },
    { path: require("./PaloAlto/pa_guest.jpg") },
    { path: require("./PaloAlto/pa_kidsbath1.jpg") },
    { path: require("./PaloAlto/pa_boys.jpg") },
    { path: require("./PaloAlto/pa_kidsbath2.jpg") },
    { path: require("./PaloAlto/pa_toys.jpg") },
  ],
  sanfrancisco: [
    { path: require("./SanFrancisco/sf_living.jpg") },
    { path: require("./SanFrancisco/sf_kitchen_1.jpg") },
    { path: require("./SanFrancisco/sf_kitchen_2.jpg") },
    { path: require("./SanFrancisco/sf_masterview.jpg") },
    { path: require("./SanFrancisco/sf_master.jpg") },
    { path: require("./SanFrancisco/sf_masterbath_3.jpg") },
    { path: require("./SanFrancisco/sf_masterbath_2.jpg") },
    { path: require("./SanFrancisco/sf_masterbath_1.jpg") },
    { path: require("./SanFrancisco/sf_master_makeup.jpg") },
    { path: require("./SanFrancisco/sf_boys_1.jpg") },
    { path: require("./SanFrancisco/sf_boysbath.jpg") },
    { path: require("./SanFrancisco/sf_boys_2.jpg") },
  ],
  napa: [
    { path: require("./Napa/napa_entry.jpg") },
    { path: require("./Napa/napa_great_room_1.jpg") },
    { path: require("./Napa/napa_great_room_2.jpg") },
    { path: require("./Napa/napa_reading_nook.jpg") },
    { path: require("./Napa/napa_kitchen.jpg") },
    { path: require("./Napa/napa_pool_1.jpg") },
    { path: require("./Napa/napa_pool_2.jpg") },
    { path: require("./Napa/napa_downstairs_guest_1.jpg") },
    { path: require("./Napa/napa_powderbath_2.jpg") },
    { path: require("./Napa/napa_guest_bath_1.jpg") },
    { path: require("./Napa/napa_powderbath_1.jpg") },
    { path: require("./Napa/napa_downstairs_guest_2.jpg") },
    { path: require("./Napa/napa_bunkroom.jpg") },
    { path: require("./Napa/napa_bb_detail_007.jpg") },
    { path: require("./Napa/napa_bb_detail_012.jpg") },
    { path: require("./Napa/napa_master.jpg") },
    { path: require("./Napa/napa_b_detail_1.jpg") },
    { path: require("./Napa/napa_master.jpg") },
    { path: require("./Napa/napa_master_bath.jpg") },
    { path: require("./Napa/napa_mb_detail_005.jpg") },
    { path: require("./Napa/napa_mb_detail_007.jpg") },
    { path: require("./Napa/napa_gameroom_1.jpg") },
    { path: require("./Napa/napa_gr_detail_1.jpg") },
    { path: require("./Napa/napa_gr_detail_2.jpg") },
  ],
};
