import React from "react";
import { ReactComponent as Rambeau } from "Assets/Icons/rambeau_interior_design.svg";
import { FlexRowContainer, FlexColumnContainer, MainAnimatedContainer } from "Styles/Grids";
import { Icon } from "Styles/Icons";
import { ImageContainer, Image } from "Styles/Images";
import { Span } from "Styles/Text";
import { AnchorTag } from "Styles/Links";
import desk from "Assets/Images/desk.jpg";

const Contact = () => {
  const styleMail = {
    color: "red",
    textDecoration: "underline",
  };

  return (
    <MainAnimatedContainer hght={"calc(100vh - 100px)"}>
      <FlexColumnContainer paddingtop={"20rem"} padding={"4rem"}>
        <FlexColumnContainer marginbottom={"2rem"}>
          <ImageContainer marginbottom={"4rem"}>
            <Image src={desk} alt="Rambeau" />
          </ImageContainer>
        </FlexColumnContainer>

        <FlexColumnContainer>
          <FlexRowContainer marginbottom={"10px"} wdth={"20rem"}>
            <Icon size={"xlarge"} component={<Rambeau />} fillcolor={"var(--color-grey-mid-1)"} />
          </FlexRowContainer>
          <FlexRowContainer marginbottom={"5px"}>
            <Span href="tel:1-415-839-6426">Office: 415.839.6426</Span>
          </FlexRowContainer>

          <Span>
            <AnchorTag style={styleMail} href="mailto:info@rambeaudesign.com">
              info@rambeaudesign.com
            </AnchorTag>
          </Span>
        </FlexColumnContainer>
      </FlexColumnContainer>
    </MainAnimatedContainer>
  );
};

export default Contact;
