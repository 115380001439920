import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import Top from "Containers/Top/Top";

import "Styles/fonts.css";

const GlobalStyle = createGlobalStyle`
  :root {
    --color-black: #000;
		--color-white: #fff;
    --color-white-trans:rgba(256, 256, 256, .6);

    --color-bg-light: #f9f9f9;

    --color-grey-light-1:#dcdcdc;
    --color-grey-light-2: #ccc;
    --color-grey-light-3: #bfbfbf;
    --color-grey-trans:rgba(36, 36, 36, .6);

    --color-grey-mid-1: #4d4d4d;


    /* Color Palette */
    --color-primary: rgb(198, 153, 99);
    --color-primary-dark: #a37035;

    --small-font-size: 1.2rem;
    --medium-font-size: 1.4rem;
    --large-font-size: 2rem;
  }
  /* Global StyleSheet Reset */
  *,
  *::after,
  *::before {
      margin: 0;
      padding: 0;  
      box-sizing: inherit;
  } 
  html {
    /*  This defines 1 rem as a global setting */
		font-size: 62.5%; /* 1rem = 10px  10px/16px = 62.5% */
    font-family: 'Questrial','Didact Gothic','Muli', sans-serif;
    scroll-behavior: smooth;
  }
  body {
    font-size: var(--medium-font-size);
    color: var(--color-grey-mid-1);
    font-weight: 400;
    box-sizing: border-box; /* Gets rid of margin/padding additions to all boxes */
  }
`;

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Top />
    </BrowserRouter>
  );
}

export default App;
