// List of Images, titles, etc. Key is the city name that matches the route
// One array of Images per city/project
// The city 'key' needs to match the route path in the TileImages.js file
export const FullImages = {
  brentwood: [
    require("./Brentwood/bw_exterior.jpg"),
    require("./Brentwood/bw_entry_2.jpg"),
    require("./Brentwood/bw_entry_1.jpg"),
    require("./Brentwood/bw_powder_room_1.jpg"),
    require("./Brentwood/bw_music_room_4.jpg"),
    require("./Brentwood/bw_music_room_1.jpg"),
    require("./Brentwood/bw_music_room_3.jpg"),
    require("./Brentwood/bw_details_014.jpg"),
    require("./Brentwood/bw_great_room_1.jpg"),
    require("./Brentwood/bw_great_room_2.jpg"),
    require("./Brentwood/bw_great_room_3.jpg"),
    require("./Brentwood/bw_bar.jpg"),
    require("./Brentwood/bw_dining_room_2.jpg"),
    require("./Brentwood/bw_dining_room_1.jpg"),
    require("./Brentwood/bw_kitchen_4.jpg"),
    require("./Brentwood/bw_kitchen_1.jpg"),
    require("./Brentwood/bw_kitchen_2.jpg"),
    require("./Brentwood/bw_kitchen_3.jpg"),
    require("./Brentwood/bw_family_room.jpg"),
    require("./Brentwood/bw_details_036.jpg"),
    require("./Brentwood/bw_details_042.jpg"),
    require("./Brentwood/bw_her_office_2.jpg"),
    require("./Brentwood/bw_her_office_1.jpg"),
    require("./Brentwood/bw_his_office.jpg"),
    require("./Brentwood/bw_his_bedroom_2.jpg"),
    require("./Brentwood/bw_details_002.jpg"),
    require("./Brentwood/bw_upstairs_landing.jpg"),
    require("./Brentwood/bw_wt_bedroom_2.jpg"),
    require("./Brentwood/bw_wt_bedroom_1.jpg"),
    require("./Brentwood/bw_details_026.jpg"),
    require("./Brentwood/bw_bunkroom_1.jpg"),
    require("./Brentwood/bw_bunkroom_2.jpg"),
    require("./Brentwood/bw_details_035.jpg"),
    require("./Brentwood/bw_his_bedroom_1.jpg"),
    require("./Brentwood/bw_his_bathroom_1.jpg"),
    require("./Brentwood/bw_master_bedroom_1.jpg"),
    require("./Brentwood/bw_master_bedroom_2.jpg"),
    require("./Brentwood/bw_master_her_bathroom_1.jpg"),
    require("./Brentwood/bw_master_her_bathroom_2.jpg"),
    require("./Brentwood/bw_master_his_bathroom_1.jpg"),
    require("./Brentwood/bw_primary_patio.jpg"),
    require("./Brentwood/bw_laundry.jpg"),
    require("./Brentwood/bw_laundry_2.jpg"),
    require("./Brentwood/bw_elevator.jpg"),
    require("./Brentwood/bw_theater_1.jpg"),
    require("./Brentwood/bw_theater_2.jpg"),
    require("./Brentwood/bw_theater_3.jpg"),
    require("./Brentwood/bw_game_room_4.jpg"),
    require("./Brentwood/bw_game_room_3.jpg"),
    require("./Brentwood/bw_game_room_1.jpg"),
    require("./Brentwood/bw_game_room_2.jpg"),
    require("./Brentwood/bw_wine_3.jpg"),
    require("./Brentwood/bw_wine_2.jpg"),
    require("./Brentwood/bw_wine_1.jpg"),
    require("./Brentwood/bw_t_bath_1.jpg"),
    require("./Brentwood/bw_game_patio.jpg"),
  ],
  laurelhurst: [
    require("./Laurelhurst/1-l-lake.jpg"),
    require("./Laurelhurst/2-l-deck.jpg"),
    require("./Laurelhurst/3-l-dining-1.jpg"),
    require("./Laurelhurst/4-l-dining-2.jpg"),
    require("./Laurelhurst/5-l-living-1.jpg"),
    require("./Laurelhurst/6-l-living-3.jpg"),
    require("./Laurelhurst/7-l-living-6.jpg"),
    require("./Laurelhurst/8-l-living-7.jpg"),
    require("./Laurelhurst/9-l-library-1.jpg"),
    require("./Laurelhurst/10-l-kitchen-1.jpg"),
    require("./Laurelhurst/11-l-kitchen-3.jpg"),
    require("./Laurelhurst/12-l-kitchen-2.jpg"),
    require("./Laurelhurst/13-l-family-1.jpg"),
    require("./Laurelhurst/14-l-master-1.jpg"),
    require("./Laurelhurst/15-l-master-2.jpg"),
    require("./Laurelhurst/16-l-kids-1.jpg"),
    require("./Laurelhurst/17-l-kids-2.jpg"),
    require("./Laurelhurst/18-l-bath.jpg"),
    require("./Laurelhurst/19-l-guest-2.jpg"),
    require("./Laurelhurst/20-l-guest-3.jpg"),
    require("./Laurelhurst/21-l-laundry.jpg"),
    require("./Laurelhurst/22-l-play-1.jpg"),
    require("./Laurelhurst/23-l-play-2.jpg"),
    require("./Laurelhurst/24-l-play-3.jpg"),
    require("./Laurelhurst/25-l-art-1.jpg"),
  ],
  napa: [
    require("./Napa/napa_entry.jpg"),
    require("./Napa/napa_great_room_1.jpg"),
    require("./Napa/napa_great_room_2.jpg"),
    require("./Napa/napa_reading_nook.jpg"),
    require("./Napa/napa_kitchen.jpg"),
    require("./Napa/napa_pool_1.jpg"),
    require("./Napa/napa_pool_2.jpg"),
    require("./Napa/napa_downstairs_guest_1.jpg"),
    require("./Napa/napa_powderbath_2.jpg"),
    require("./Napa/napa_guest_bath_1.jpg"),
    require("./Napa/napa_powderbath_1.jpg"),
    require("./Napa/napa_downstairs_guest_2.jpg"),
    require("./Napa/napa_bunkroom.jpg"),
    require("./Napa/napa_bb_detail_007.jpg"),
    require("./Napa/napa_bb_detail_012.jpg"),
    require("./Napa/napa_master.jpg"),
    require("./Napa/napa_b_detail_1.jpg"),
    require("./Napa/napa_master.jpg"),
    require("./Napa/napa_master_bath.jpg"),
    require("./Napa/napa_mb_detail_005.jpg"),
    require("./Napa/napa_mb_detail_007.jpg"),
    require("./Napa/napa_gameroom_1.jpg"),
    require("./Napa/napa_gr_detail_1.jpg"),
    require("./Napa/napa_gr_detail_2.jpg"),
  ],
  santamonica: [
    require("./SantaMonica/21st_Entry.jpg"),
    require("./SantaMonica/21st_Great_Room.jpg"),
    require("./SantaMonica/21st_Bar.jpg"),
    require("./SantaMonica/21st_Kitchen_Nook.jpg"),
    require("./SantaMonica/21st_Kitchen.jpg"),
    require("./SantaMonica/21st_Dining_Room.jpg"),
    require("./SantaMonica/21st_Patio_2.jpg"),
    require("./SantaMonica/21st_TV_Room.jpg"),
    require("./SantaMonica/21st_TV_Room_2.jpg"),
    require("./SantaMonica/21st_Guest_Bedroom.jpg"),
    require("./SantaMonica/21st_Stairs.jpg"),
    require("./SantaMonica/21st_Master_Bedroom.jpg"),
    require("./SantaMonica/21st_Kids_Room.jpg"),
    require("./SantaMonica/21st_Kids_Room_2.jpg"),
    require("./SantaMonica/21st_J&J_Bathroom.jpg"),
    require("./SantaMonica/21st_Office.jpg"),
    require("./SantaMonica/21st_Nanny_Room.jpg"),
  ],
  marvista: [
    require("./MarVista/mv_living_2.jpg"),
    require("./MarVista/mv_living_3.jpg"),
    require("./MarVista/mv_living_4.jpg"),
    require("./MarVista/mv_living_1.jpg"),
    require("./MarVista/mv_detail_008.jpg"),
    require("./MarVista/mv_detail_042.jpg"),
    require("./MarVista/mv_light_detail_1.jpg"),
    require("./MarVista/mv_dining.jpg"),
    require("./MarVista/mv_light_detail_2.jpg"),
    require("./MarVista/mv_family.jpg"),
    require("./MarVista/mv_master.jpg"),
    require("./MarVista/mv_office_1.jpg"),
    require("./MarVista/mv_office_2.jpg"),
  ],
  woodsideone: [],
  woodsidetwo: [],
  woodsidethree: [],
  menloparkone: [],
  menloparktwo: [],
  menloparkthree: [],
  paloalto: [],
  sanfrancisco: [],
};
