import { cloneElement } from "react";
import styled from "styled-components";

export const IconContainer = styled.div`
  position: ${({ pos }) => pos};
  display: flex;
  height: ${({ hght }) => hght};
  width: ${({ wdth }) => wdth};
  margin-left: ${({ marginleft }) => marginleft};
  margin-right: ${({ marginright }) => marginright};
  margin-bottom: ${({ marginbottom }) => marginbottom};
  padding-bottom: ${({ paddingbottom }) => paddingbottom};
  padding-top: ${({ paddingtop }) => paddingtop};
  padding-left: ${({ paddingleft }) => paddingleft};
  padding-right: ${({ paddingright }) => paddingright};
  transition: all 200ms;
  align-items: center;
  justify-content: ${({ justifycontent }) => justifycontent};
  justify-self: ${({ justifyself }) => justifyself};
  background-color: ${({ bgcolor }) => bgcolor};
  border: ${({ outlineborder }) => (outlineborder ? `1px solid ${outlineborder}` : null)};
  border-radius: ${({ bradius }) => bradius};
  &:hover {
    cursor: ${({ hover }) => (hover ? "pointer" : null)};
  }
  &:hover > svg {
    fill: ${({ hoverfill }) => hoverfill};
  }
`;

export const Icon = styled(({ component, ...props }) => cloneElement(component, props))`
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  z-index: ${({ zindex }) => zindex};
  fill: ${({ fillcolor }) => fillcolor};
  opacity: ${({ opacity }) => opacity};
  transform: ${({ rotate }) => rotate};
  transition: all 300ms;
  padding-top: ${({ paddingtop }) => paddingtop};
  padding-bottom: ${({ paddingbottom }) => paddingbottom};
  padding-left: ${({ paddingleft }) => paddingleft};
  padding-right: ${({ paddingright }) => paddingright};
  justify-self: ${({ justifyself }) => justifyself};
`;

export const ButtonContainer = styled.div`
  transition: transform 200ms;
  margin-top: 2rem;
  margin-left: 2rem;
  &:hover {
    fill: ${({ hover }) => (hover ? "red" : "var(--color-black);")};
    cursor: ${({ hover }) => (hover ? "pointer" : null)};
  }
  &:hover > svg {
    fill: ${({ hover }) => (hover ? "red" : "black")};
  }
`;
