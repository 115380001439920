import styled from "styled-components";
import { animated } from "react-spring";

export const TileContainer = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
  &:hover div {
    opacity: 0;
  }
  &:hover figure {
    transform: scale(1.05);
  }
  &:hover span {
    color: var(--color-black);
  }
`;
export const ImageContainer = styled(animated.figure)`
  width: ${({ wdth }) => wdth};
  height: ${({ hght }) => hght};
  transition: all 1500ms;
  &:hover span {
    color: var(--color-black);
  }
`;
export const ImageHoverContainer = styled(animated.figure)`
  width: ${({ wdth }) => wdth};
  height: ${({ hght }) => hght};
  transition: all 1500ms;
  overflow: hidden;
`;
export const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: all 1500ms;
`;
export const ImageP = styled.img`
  object-fit: cover;
  height: ${({ hght }) => hght};
  width: 100%;
`;
export const ImageL = styled.img`
  object-fit: cover;
  width: 100%;
  height: ${({ hght }) => hght};
`;

export const GreyCube = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-grey-trans);
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: opacity 300ms;
`;
