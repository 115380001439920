import React, { useState } from "react";
import styled from "styled-components";
import { animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import MenuBackground from "Components/Menu/MenuBG";

const MenuContainer = styled(animated.div)`
  position: fixed;
  width: 100%;
  z-index: 10;
`;
const BurgerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 4.5rem;
  margin-right: 3rem;
  height: 4rem;
  padding-top: 5px;
  z-index: 20;
  &:hover {
    cursor: pointer;
  }
`;
const TopContainer = styled.div`
  position: relative;
  top: ${(menu) => (menu === "open" ? 0 : "-5px")};
  right: 0px;
  width: 4rem;
  transform: ${({ menu }) => (menu === "open" ? "rotate(45deg)" : "rotate(0deg)")};
  transform-origin: left;
  transition: all 250ms;
  &:after {
    background-color: ${({ menu }) => (menu === "open" ? "var(--color-white);" : "var(--color-black);")};
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    border-radius: 5px;
  }
`;
const Middle1Container = styled.div`
  position: absolute;
  top: 14px;
  right: 0px;
  width: 4rem;
  opacity: ${({ menu }) => (menu === "open" ? 0 : 1)};
  transition: all 100ms;
  &:after {
    background-color: var(--color-black);
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    border-radius: 3px;
  }
`;
const BottomContainer = styled.div`
  position: absolute;
  top: 28px;
  right: 0px;
  width: 4rem;
  transform: ${({ menu }) => (menu === "open" ? "rotate(-45deg)" : "rotate(0deg)")};
  transform-origin: left;
  transition: all 250ms;
  &:after {
    background-color: ${({ menu }) => (menu === "open" ? "var(--color-white);" : "var(--color-black);")};
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    border-radius: 3px;
    transform: rotate(135);
  }
`;

const Menu = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState("close");

  const onClickMenuHandler = () => {
    if (menu === "open") {
      setMenu("close");
    }
    if (menu === "close") {
      setMenu("open");
    }
  };

  const onClickLinkHandler = (link) => {
    setMenu("close");
    navigate(link);
  };

  return (
    <MenuContainer>
      <BurgerContainer onClick={onClickMenuHandler}>
        <TopContainer menu={menu} />
        <Middle1Container menu={menu} />
        <BottomContainer menu={menu} />
      </BurgerContainer>

      {menu === "open" ? <MenuBackground menu={menu} clicked={onClickLinkHandler} /> : null}
    </MenuContainer>
  );
};

export default Menu;
