import React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { Icon } from "Styles/Icons";
import { ReactComponent as Logo } from "Assets/Icons/logo_white.svg";
import { FlexColumnContainer } from "Styles/Grids";

const MenuBG = styled(animated.div)`
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: var(--color-black);
`;
const CenterIconContainer = styled(animated.div)`
  position: relative;
  width: 15rem;
  height: 15rem;
  padding-top: 3rem;
  padding-left: 4rem;
  opacity: ${({ load }) => (load === "open" ? 0 : 1)};
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 600px) {
    left: 0;
  }
  /* @media only screen and (min-width: 601px) {
    margin-top: 5px;
    left: 50%;
    transform: translateX(-50%);
  } */
`;
const NavContainer = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 300px);
  justify-content: center;
`;
const LabelContainer = styled.div`
  margin-top: 2rem;
  margin-left: 5rem;
  ${
    "" /* &: hover span {
    color: red;
  } */
  }
`;
const Label = styled.span`
  position: relative;
  text-transform: uppercase;
  color: var(--color-white);
  font-size: var(--large-font-size);
  font-family: "Didact Gothic";
  display: block;
  width: fit-content;
  padding-left: 1rem;
  padding-right: 1rem;
  &:after {
    background-color: red;
    content: "";
    display: block;
    height: 2px;
    width: 0px;
    border-radius: 3px;
    transition: width 200ms;
  }
  &:hover {
    cursor: pointer;
    color: red;
  }
  &:hover:after {
    width: 100%;
  }
`;
const ContactEmail = styled.span`
  display: block;
  width: fit-content;
  text-transform: uppercase;
  color: var(--color-white);
  font-size: var(--medium-font-size);
  font-family: "Didact Gothic";
  margin-left: 5rem;
  margin-bottom: 0.5rem;
`;

const MenuBackground = ({ menu, clicked }) => {
  const bgFadeIn = useSpring({
    from: { opacity: menu === "open" ? 0 : 1 },
    to: { opacity: menu === "open" ? 1 : 0 },
    config: { duration: 500 },
  });
  return (
    <MenuBG style={bgFadeIn} menu={menu}>
      <CenterIconContainer onClick={() => clicked("/")}>
        <Icon size={"large"} component={<Logo />} />
      </CenterIconContainer>

      <NavContainer>
        <LabelContainer onClick={() => clicked("projects/brentwood")}>
          <Label>New</Label>
        </LabelContainer>
        <LabelContainer onClick={() => clicked("projects")}>
          <Label>Projects</Label>
        </LabelContainer>
        <LabelContainer onClick={() => clicked("careers")}>
          <Label>Careers</Label>
        </LabelContainer>
        <LabelContainer onClick={() => clicked("contact")}>
          <Label>Contact</Label>
        </LabelContainer>
      </NavContainer>
      <FlexColumnContainer>
        <ContactEmail>Rambeau Interior Design</ContactEmail>
        <ContactEmail>info@rambeaudesign.com</ContactEmail>
      </FlexColumnContainer>
    </MenuBG>
  );
};

export default MenuBackground;
