import React, { useEffect } from "react";
import styled from "styled-components";
import { HyperLink } from "Styles/Links";
import { CustomGridColumns } from "Styles/Grids";
import { ImageContainer, Image, TileContainer, GreyCube } from "Styles/Images";
import { LabelContainer, Span } from "Styles/Text";

export const Images = [
  { tile: require("Assets/Images/Projects/PreviewTiles/bw.jpg"), title: "Brentwood", linkPath: "/projects/brentwood" },
  { tile: require("Assets/Images/Projects/PreviewTiles/l.jpg"), title: "Laurelhurst", linkPath: "/projects/laurelhurst" },
  { tile: require("Assets/Images/Projects/PreviewTiles/napa.jpg"), title: "Napa", linkPath: "/projects/napa" },
  { tile: require("Assets/Images/Projects/PreviewTiles/sm1.jpg"), title: "Santa Monica", linkPath: "/projects/santamonica" },
  { tile: require("Assets/Images/Projects/PreviewTiles/mv.jpg"), title: "Mar Vista", linkPath: "/projects/marvista" },
  { tile: require("Assets/Images/Projects/PreviewTiles/w1.jpg"), title: "Woodside I", linkPath: "/projects/woodsideone" },
  { tile: require("Assets/Images/Projects/PreviewTiles/w2.jpg"), title: "Woodside II", linkPath: "/projects/woodsidetwo" },
  { tile: require("Assets/Images/Projects/PreviewTiles/pa1.jpg"), title: "Palo Alto", linkPath: "/projects/paloalto" },
  { tile: require("Assets/Images/Projects/PreviewTiles/w3.jpg"), title: "Woodside III", linkPath: "/projects/woodsidethree" },
  { tile: require("Assets/Images/Projects/PreviewTiles/mp1.jpg"), title: "Menlo Park I", linkPath: "/projects/menloparkone" },
  { tile: require("Assets/Images/Projects/PreviewTiles/mp2.jpg"), title: "Menlo Park II", linkPath: "/projects/menloparktwo" },
  { tile: require("Assets/Images/Projects/PreviewTiles/mp3.jpg"), title: "Menlo Park III", linkPath: "/projects/menloparkthree" },
  { tile: require("Assets/Images/Projects/PreviewTiles/sf1.jpg"), title: "San Francisco", linkPath: "/projects/sanfrancisco" },
];

const MainContainer = styled.section`
  margin-top: 3px;
`;

const Projects = () => {
  useEffect(() => window.scrollTo(0, 0));

  const tileJsx = Images.map((image, index) => {
    return (
      <TileContainer key={index}>
        <HyperLink to={{ pathname: image.linkPath }}>
          <ImageContainer marginbottom={"-1rem"}>
            <Image src={image.tile} alt={image.title} />
          </ImageContainer>
          <GreyCube />
          <LabelContainer op={"op"}>
            <Span fontsize={"var(--large-font-size)"} color={"var(--color-white)"}>
              {image.title}
            </Span>
          </LabelContainer>
        </HyperLink>
      </TileContainer>
    );
  });

  return (
    <MainContainer>
      <CustomGridColumns gridcolumns={"repeat(auto-fill, minmax(40rem, 1fr))"} gcgap={"5px}"}>
        {tileJsx}
      </CustomGridColumns>
    </MainContainer>
  );
};

export default Projects;
